/* .app-body-mam {
    overflow: scroll;
} */
.main .container-fluid {
  height: 90vh;
  padding-right: 0;
  padding-top: 10px;
}

/* .ReactTable {
    max-height: 70vh;
    width: 100%;
} */
.ReactTable .rt-tbody .rt-tr-group .rt-tr {
  height: 19px;
}

.ReactTable .rt-tr-group .rt-tr .rt-td {
  display: flex;
  align-items: center;
}

/* @media screen and (min-width: 1600px) {
    .ReactTable {
        max-height: 76vh;
    }
} */

.ReactTable .rt-tbody {
  max-height: unset !important;
}
.ag-theme-alpine {
  --ag-grid-size: 4px;
  --ag-list-item-height: 20px;
  --ag-header-column-resize-handle-display: block;
  --ag-header-column-resize-handle-height: 100%;
  --ag-header-column-resize-handle-width: 20px;
  --ag-header-column-resize-handle-color: rgb(216, 38, 38);
  --ag-header-background-color: rgb(223, 207, 251);  
    margin-top: 10px;
    margin-bottom:20px;
}
div.ag-theme-alpine div.ag-row {
  font-size: 12px !important;
  
}
div.ag-theme-alpine div.ag-cell{
  line-height: 40px !important;
  padding-left: 5px !important;
  padding-right: 2px !important;
}



